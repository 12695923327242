export const NFT_DEFAULT_PRICING = {
  address: '',
  collectionName: ''
};
export const roles = [
  {
    label: 'Admin',
    value: 'new'
  },
  {
    label: 'Member',
    value: 'member'
  }
];

// export const PAYMENT_INTERVAL_OPTIONS = [
//   {
//     weight: 1,
//     label: '/month',
//     value: 'monthly'
//   },
//   {
//     weight: 2,
//     label: '/3 months',
//     value: 'quarterly'
//   },
//   {
//     weight: 3,
//     label: '/6 months',
//     value: 'semi-annual'
//   },
//   {
//     weight: 4,
//     label: '/year',
//     value: 'annual'
//   }
// ];

export const PAYMENT_INTERVAL_OPTIONS = [
  {
    weight: 1,
    label: '/month',
    value: 'monthly',
    interval: 'month',
    intervalCount: 1
  },
  {
    weight: 2,
    label: '/3 months',
    value: 'quarterly',
    interval: 'month',
    intervalCount: 3
  },
  {
    weight: 3,
    label: '/6 months',
    value: 'semi-annual',
    interval: 'month',
    intervalCount: 6
  },
  {
    weight: 4,
    label: '/year',
    value: 'annual',
    interval: 'year',
    intervalCount: 1
  }
];
