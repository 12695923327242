import { PAYMENT_INTERVAL_OPTIONS } from '@/contexts/SettingsSetupContext/constants';
import config from './config';

export const getWaBotPhoneNumber = (community) => {
  try {
    const botPhoneNumber = getPhoneNumberFromWaPhoneId(
      community?.managedByWhatsappBot
    );
    return botPhoneNumber;
  } catch (e) {
    return config.fallbackWhatsappBotNumber;
  }
};

export const getPhoneNumberFromWaPhoneId = (waPhoneId) => {
  try {
    if (waPhoneId.startsWith('+') && !waPhoneId.includes('@'))
      return waPhoneId;
    const phoneNumber = '+' + waPhoneId.split('@')[0];
    return phoneNumber;
  } catch (e) {
    return config.fallbackWhatsappBotNumber || waPhoneId;
  }
};

export const getWhatsAppConnectionMetadata = (community) => {
  try {
    const {
      bots,
      isWhatsappExperienceCommunity,
      isWhatsappBotAdmin,
      isWhatsappBotInGroup
    } = community;
    const botWasPreviouslyConnected = !!bots?.length;
    const isBotAdminOfTheGroup =
      isWhatsappBotAdmin && isWhatsappBotInGroup;

    // Show connect flow if bot was NOT previously connected and bot is NOT admin of the group
    const showWhatAppConnectFlow =
      isWhatsappExperienceCommunity &&
      !isBotAdminOfTheGroup &&
      !botWasPreviouslyConnected;

    // Show re-connect flow if bot was previously connected and bot is NOT admin of the group
    const showWhatAppReconnectFlow =
      isWhatsappExperienceCommunity &&
      !isBotAdminOfTheGroup &&
      botWasPreviouslyConnected;

    // Show WA connection card if bot is NOT admin of the group
    const showWaConnectionCard =
      isWhatsappExperienceCommunity && !isBotAdminOfTheGroup;

    return {
      showWaConnectionCard,
      showWhatAppConnectFlow,
      showWhatAppReconnectFlow
    };
  } catch (e) {
    return {
      showWaConnectionCard: false,
      showWhatAppConnectFlow: false,
      showWhatAppReconnectFlow: false
    };
  }
};

// temporary formatting for new pricing api
export const formatNewPricingApiToOldFormat = (newPricingDetails) => {
  const formattedNewPricing = newPricingDetails?.prices?.map((price) => ({
    currency: price.currency,
    product: newPricingDetails?.productId,
    recurring: {
      interval: price.interval,
      interval_count: price.intervalCount
    },
    type: 'recurring',
    unit_amount: price.unitAmount,
    localCurrency: price.localCurrency,
    localPrice: price.localPrice,
    id: price.id
  }));

  return formattedNewPricing;
};

export const getAvailablePaymentIntervals = (currPaymentIntervals) => {
  const res = PAYMENT_INTERVAL_OPTIONS.filter((paymentIntervalOption) => {
    const isSelectedPaymentInterval = !!currPaymentIntervals.find(
      (selectedPaymentInterval) =>
        selectedPaymentInterval.value === paymentIntervalOption.value
    );

    return !isSelectedPaymentInterval; // acceptable if not selected in currPaymentIntervals
  });

  return res;
};

export const formatPaymentIntervalsForApi = (
  communityPaymentIntervals
) => {
  if (!Array.isArray(communityPaymentIntervals)) return [];

  const formattedPaymentIntervals = communityPaymentIntervals.map(
    (item) => {
      return {
        currency: item?.currency,
        amount: item?.amount,
        interval: item.interval,
        intervalCount: item.intervalCount
      };
    }
  );
  return formattedPaymentIntervals;
};
